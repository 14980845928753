@import "../../css-config/mixins.scss";

.main-header {
    background-color: black;
    color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 99;
    width: 100%;
    padding: 0 20px;
    position: relative ;

    @include md {
        padding: 0 40px;
    }

    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        height: 100px;

        .toggle_btn{
            display: none;
        }

        .left {
            gap: 25px;

            @include md {
                display: flex;
            }

            li {
                list-style: none;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;

                @include md {
                    font-size: 16px;
                }
            }
        }

        .center {
            font-size: 22px;
            font-weight: 700;
            cursor: pointer;

            img {
                height: 50px;

                @include md {
                    height: 100px;
                }
            }

            @include md {
                font-size: 34px;
                position: absolute;
                right: 50%;
                transform: translateX(50%);
            }
        }

        .right {
            display: flex;
            align-items: center;

            svg {
                font-size: 20px;
                cursor: pointer;

                @include md {
                    font-size: 24px;
                }
            }

        }

    }

    .dropdown-menu{
        display: none;
        width: 100%;
        background : rgba(0,0,0,0.7);
        backdrop-filter: blur(15px);
        height: 0;

        li{
            list-style-type: none;
            padding: 0.7rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .dropdown-menu.open{
        height: 200px;
        display: block;
    }

    &.sticky-header {
        position: sticky;
        top: 0;
        transform: translateY(-60px);
        animation: stickyHeader 0.5s ease forwards;
    }
}

@keyframes stickyHeader {
    0% {
        transform: translateY(-60px);
    }

    100% {
        transform: translateY(0);
    }
}

@media(max-width:992px){
    .main-header{
        .header-content{
            .left{
                display: none;
            }

            .toggle_btn{
                display: block;
            }
        }
    }
}