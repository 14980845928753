@import "../../css-config/mixins.scss";

.footer {
    width: 100%;
    background: #f9f9f9 url("../../assets/s_lamps.jpeg");
    color: white;

    .custom-shape-divider {
        width: 100%;
        overflow: hidden;
        line-height: 0;
        

        svg {
            position: relative;
            display: block;
            width: calc(191% + 1.3px);
            height: 114px;
        }

        .shape-fill {
            fill: rgba(255, 248, 231, 255);
        }
    }

    .footer-inner {
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(10px);
        .footer-content {

            padding: 50px 20px;
            max-width: 1200px;
            margin: 0 auto;
            display: flex;
            flex-flow: wrap;
            gap: 20px;

            @include md {
                justify-content: space-between;
                padding: 50px 0;
            }

            .col {
                max-width: 400px;

                img {
                    height: 100px;

                    @include md {
                        height: 150px;
                    }
                }

                .title {
                    margin-bottom: 20px;
                    font-size: 20px;
                }

                .text {
                    font-size: 16px;
                }

                .ol {
                    cursor: pointer;
                    text-decoration: underline;
                }

                .c-items {
                    display: flex;
                    margin-bottom: 10px;

                    svg {
                        flex-shrink: 0;
                        font-size: 14px;
                        margin-right: 10px;
                        margin-top: 4px;
                    }
                }

                span {
                    &.text {
                        display: block;
                        margin-bottom: 10px;
                    }
                }

                .form {
                    display: flex;
                    gap: 5px;
                    margin-bottom: 10px;
                    justify-content: center;

                    input {
                        width: 200px;
                        height: 40px;
                        border-radius: 0;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        padding: 0 12px;
                        font-size: 16px;
                        outline: 0;

                        @include md {
                            width: 300px;
                        }
                    }

                    .wa-btn {
                        text-decoration: none;
                        outline: 0;
                        border: 0;
                        padding: 10px;
                        display: flex;
                        font-weight: bold;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        font-size: 16px;
                        color: white;
                        border-radius: 3px;
                        background-color: rgb(20, 98, 20);

                        @include md {
                            widows: 120px;
                        }

                        svg {
                            margin: 0px 8px 0px 0px;
                        }
                    }
                }

                .social-icons {
                    display: flex;
                    gap: 10px;
                    margin: 10px 0px;
                    justify-content: center;


                    .icon {
                        width: 40px;
                        height: 40px;
                        background-color: rgba(0, 0, 0, 0.6);
                        border-radius: 50%;
                        color: rgb(255, 255, 255);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                }
            }
        }

        .bottom-bar {
            border-top: 1px solid white;

            .bottom-bar-content {
                padding: 20px;
                display: flex;
                text-align: center;
                align-items: center;
                flex-direction: column;
                gap: 10px;

                @include md {
                    padding: 0;
                    height: 60px;
                    min-width: 1200px;
                    margin: 0 10px;
                    flex-direction: row;
                    justify-content: space-between;
                    text-align: left;
                }

                .text {
                    font-size: 12px;
                    color: white;
                }

                .text_u {
                    u {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}