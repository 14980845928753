@import "../../css-config/mixins.scss";

.category-main-content{
    margin-top: 30px;

    @include md{
        margin-top: 75px;
    }

    .layout{
        max-width: calc(100% - 20px);
        margin: 0 auto;

        @include md{
            max-width: 1200px;
        }
    }

    .title{
        font-size: 24px;
        text-transform: capitalize;

        @include md{
            font-size: 34px;
        }
    }

    .products-container{
        margin: 20px 0;

        @include md{
            margin-top: 50px;
        }
    }
}