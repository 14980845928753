@import "../../css-config/mixins.scss";

.whychooseus {
    margin: 25px 0;

    @include md {
        margin: 50px 0;
    }

    .sec-heading {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;

        @include md {
            margin-bottom: 35px;
            font-size: 24px;
        }

        &:after {
            content: "";
            display: block;
            margin-top: 5px;
            width: 50px;
            height: 3px;
            background-color: rgb(225, 167, 19);

            @include md {
                margin-top: 10px;
            }

        }
    }

    .layout {
        display: flex;
        flex-flow: wrap;
        gap: 20px;
        justify-content: center;

        .info {
            background-color: #3e3e3e;
            width: calc(50% - 15px);
            padding: 20px;
            font-size: 14px;
            display: block;
            margin: 10px 0px;
            color: rgb(242, 242, 242);
            border-radius: 15px;

            h3 {
                font-weight: bold;
                margin-bottom: 10px;
                text-transform: uppercase;
                white-space: nowrap;
                overflow: auto;
            }

            @include md {
                width: calc(33% - 15px);
                font-size: 16px;
            }

            &:hover {
                h3 {
                    color: rgb(225, 167, 19);
                    font-size: 20px;
                }
            }
        }
    }
}