@import "../../css-config/mixins.scss";

.about-us {
    margin: 25px 0;

    @include md {
        margin: 50px 0;
    }

    .sec-heading {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;

        @include md {
            margin-bottom: 35px;
            font-size: 24px;
        }

        &:after {
            content: "";
            display: block;
            margin-top: 5px;
            width: 50px;
            height: 3px;
            background-color: rgb(225, 167, 19);

            @include md {
                margin-top: 10px;
            }

        }
    }
    
    .layout {
        display: flex;
        flex-flow: wrap;
        gap: 0px;
        justify-content: center;

        .about-us-type {
            width: calc(50% - 15px);
            overflow: hidden;
            margin: 0 5px;
            text-align: -webkit-center;


            @include md {
                width: calc(33% - 15px);
            }

            img {
                border-radius: 50%;
                object-fit: cover;
                width: 100px;
                height: 100px;
                display: block;

                @include md{
                    height: 250px;
                    width: 250px;
                }
            }


            .info {
                font-size: 14px;
                display: block;
                overflow: hidden;
                margin: 10px 0px;

                h3{
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                @include md {
                    font-size: 16px;
                }
            }
        }
    }
}