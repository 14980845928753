@import "../../../css-config/mixins.scss";

.hero-banner {
    padding: 40px 0;
    position: relative;
    background-image: url(../../../assets/banner.jpeg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include md {
        height: calc(100vh - 100px);
    }

    .content {
        background-color: rgba(0,0,0,0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        max-width: calc(100vh - 20px);

        @include md {
            flex-direction: row;
            max-width: 800px;
        }

        .text-content {
            color: white;
            text-align: center;
            padding: 10px 30px;
            // display: flex;
            // flex-direction: column;
            // align-items: center;

            @include md {
                margin: 0px auto;
            }

            @include md {
                left: 0;
            }

            h1 {
                font-size: 20px;
                font-weight: 700;
                line-height: 1;
                margin-bottom: 20px;
                color: rgb(255, 191, 0);

                @include md {
                    font-size: 40px;
                }
            }

            p {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 20px;

                @include md {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            .ctas {
                display: flex;
                justify-content: center;
                gap: 20px;

                .banner-cta {
                    text-transform: uppercase;
                    font-size: 13px;
                    font-weight: 500;
                    border: 2px solid rgb(255, 191, 0);
                    padding: 10px 20px;
                    cursor: pointer;

                    &.v2 {
                        background-color: rgb(255, 191, 0);
                        color: black;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }

    }
}