@import "../../../css-config/mixins.scss";

.shop-by-category {
    margin: 25px 0;

    @include md {
        margin-top: 50px;
    }

    .sec-heading {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;

        @include md {
            margin-bottom: 35px;
            font-size: 24px;
        }

        &:after {
            content: "";
            display: block;
            margin-top: 5px;
            width: 50px;
            height: 3px;
            background-color: rgb(225, 167, 19);

            @include md {
                margin-top: 10px;
            }

        }
    }

    .categories {
        display: flex;
        flex-flow: wrap;
        gap: 5px;

        .category {
            width: calc(50% - 15px);
            cursor: pointer;
            overflow: hidden;
            height: 50vh;
            margin: 0 5px;

            @include md {
                width: calc(25% - 15px);
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 90%;
                display: block;
                transition: all ease 0.3s;
            }


            &:hover {
                img {
                    transform: scale(1.2);
                }
            }

            .prod-details {
                font-size: 14px;
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                @include md {
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

            }
        }
    }
}