@import "../../../css-config/mixins.scss";

.other-links {
    .sec-heading {
        margin: 20px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;

        @include md {
            margin-bottom: 35px;
            font-size: 24px;
        }

        &:after {
            content: "";
            display: block;
            margin-top: 5px;
            width: 50px;
            height: 3px;
            background-color: rgb(225, 167, 19);

            @include md {
                margin-top: 10px;
            }

        }
    }

    .text {
        padding: 10px;
        margin: 20px 80px;
        -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

        .it{
            padding: 5px;
        }
    }
    
}