@import "../../css-config/mixins.scss";

.contact-us {
    .sec-heading {
        margin: 20px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;

        @include md {
            margin-bottom: 35px;
            font-size: 24px;
        }

        &:after {
            content: "";
            display: block;
            margin-top: 5px;
            width: 50px;
            height: 3px;
            background-color: rgb(225, 167, 19);

            @include md {
                margin-top: 10px;
            }

        }
    }

    .outer-text {
        padding: 10px;
        margin: 20px 80px;
        text-align: center;

        .box {
            padding-top: 20px;

            .c-items {
                display: flex;
                margin-bottom: 10px;
                justify-content: center;

                svg {
                    flex-shrink: 0;
                    font-size: 14px;
                    margin-right: 10px;
                    margin-top: 4px;
                }
            }

            .social-icons {
                display: flex;
                gap: 10px;
                margin: 10px 0px;
                justify-content: center;


                .icon {
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    background-color: rgba(0, 0, 0);
                    border-radius: 50%;
                    color: rgb(255, 255, 255);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

}